import getFunctionScores from './elasticsearch/score';
import getMultiMatchConfig from './elasticsearch/multimatch';
import getBoosts from './elasticsearch/boost';
import getMapping from './elasticsearch/mapping';
import cloneDeep from 'lodash-es/cloneDeep';
import config from 'config';
import { parseTimezoneDateTo, parseTimezoneDateFrom } from '../../../../modules/catalog/helpers/parseDateTimezone';
import dayjs from 'dayjs';

export async function prepareElasticsearchQueryBody (searchQuery) {
  const bodybuilder = await import(/* webpackChunkName: "bodybuilder" */ 'bodybuilder');
  const optionsPrefix = '_options';
  const queryText = searchQuery.getSearchText();
  const rangeOperators = ['gt', 'lt', 'gte', 'lte', 'moreq', 'from', 'to'];
  let query = bodybuilder.default();

  // process applied filters
  const appliedFilters = cloneDeep(searchQuery.getAppliedFilters()); // copy as function below modifies the object
  if (appliedFilters.length > 0) {
    let hasCatalogFilters = false;
    let hasSearchString = false;
    let searchString = null;
    // apply default filters
    appliedFilters.forEach(filter => {
      // exclude
      if (filter.attribute === 'searchString') {
        hasSearchString = true;
        hasCatalogFilters = true;
        searchString = filter.value;
        if (Array.isArray(searchString)) {
          searchString = searchString[0];
        }
        return;
      }
      if (filter.scope === 'default') {
        if (Object.keys(filter.value).every(v => rangeOperators.includes(v))) {
          // process range filters
          query = query.filter('range', filter.attribute, filter.value);
        } else {
          // process terms filters
          filter.value = filter.value[Object.keys(filter.value)[0]];
          if (!Array.isArray(filter.value)) {
            filter.value = [filter.value];
          }
          query = query.filter('terms', getMapping(filter.attribute), filter.value);
        }
      } else if (filter.scope === 'catalog') {
        // Logger.debug('catalog', 'SSS', filter)()
        hasCatalogFilters = true;
      }
    });
    // apply catalog scope filters
    const attrFilterBuilder = (filterQr, attrPostfix = '') => {
      appliedFilters.forEach(catalogfilter => {
        if (catalogfilter.attribute === 'searchString') {
          return;
        }
        // do not include availability filters because are added later
        if (catalogfilter.attribute !== 'availability') {
          const valueKeys = Object.keys(catalogfilter.value);
          if (catalogfilter.scope === 'catalog' && valueKeys.length) {
            const isRange = valueKeys.filter(value => rangeOperators.indexOf(value) !== -1);
            if (isRange.length) {
              let rangeAttribute = catalogfilter.attribute;
              // filter by product final price
              if (rangeAttribute === 'price') {
                try {
                  // todo hack -> all of this is here just because the structure of data in ES isnt correct.
                  rangeAttribute = 'configurable_children.price';
                } catch (err) {
                  console.error(err);
                }
              } else {
                // process range filters
                filterQr = filterQr.andFilter('range', rangeAttribute, catalogfilter.value);
              }
            } else {
              let newValue = catalogfilter.value[Object.keys(catalogfilter.value)[0]];
              if (!Array.isArray(newValue)) {
                newValue = [newValue];
              }
              if (catalogfilter.attribute === 'partner') {
                filterQr = filterQr.andFilter('terms', 'rental_info.source_id', newValue);
              } else if (attrPostfix === '') {
                filterQr = filterQr.andFilter('terms', getMapping(catalogfilter.attribute), newValue);
              } else {
                filterQr = filterQr.andFilter('terms', catalogfilter.attribute + attrPostfix, newValue);
              }
            }
          }
        }
      });
      return filterQr;
    };

    // to add filters for query with reservations/dates
    // TODO what does this actually do? Is it necessary?
    const addFilters = q => {
      for (const filter of appliedFilters) {
        if (filter.scope === 'catalog' && filter.attribute !== 'availability') {
          if (filter.attribute !== 'price' && filter.attribute !== 'searchString') {
            q.query('terms', `configurable_children.${filter.attribute}`, filter.value.in);
          }
        }
      }
      return q;
    };
    // -
    if (hasCatalogFilters) {
      let hasAvailability = false;
      let hasPrice = false;

      // -- is there an availability obj?
      appliedFilters.forEach(filter => {
        if (filter.attribute === 'availability') {
          // if there's no date there, dont include reservations into the search
          if (!filter.value.gte || !filter.value.lte) {
            hasAvailability = false;
          } else {
            hasAvailability = true;
          }
        }
        if (filter.attribute === 'price') {
          hasPrice = true;
        }
      });
      if (hasAvailability) {
        // Logger.debug('A', 'SSS', null)()
        const result = appliedFilters.filter(obj => {
          return obj.attribute === 'availability';
        });
        // let dateFrom = result[0].value.gte;
        // let dateTo = result[0].value.lte;
        const dateFrom = parseTimezoneDateFrom(result[0].value.gte);
        const dateTo = parseTimezoneDateTo(result[0].value.lte);
        // We need to add 1 to the final calculation, because otherwise the diff betweet start of day and end of day isnt counted in
        const reservationLength = dayjs(dateTo, 'YYYY-MM-DD')
          .endOf('day')
          .diff(dayjs(dateFrom, 'YYYY-MM-DD').startOf('day'), 'days') + 1;
        query = query
          .query(
            'bool',
            c => c.orFilter('bool', attrFilterBuilder).orFilter('bool', b => attrFilterBuilder(b, optionsPrefix))
            // .orFilter('match', 'type_id', 'configurable'))
          )
          // dates + filters
          .addQuery('nested', 'path', 'configurable_children', q => {
            return addFilters(q).andQuery('bool', q3 =>
              q3
                .query('range', 'configurable_children.min_reservation_length_days', { lte: reservationLength })
                .query('nested', 'path', 'configurable_children.items', q => {
                  return q.notQuery('nested', 'path', 'configurable_children.items.reservations', q2 => {
                    return q2
                      .orQuery('bool', b =>
                        b
                          .query('range', 'configurable_children.items.reservations.from', { gt: dateFrom })
                          .query('range', 'configurable_children.items.reservations.from', { lte: dateTo })
                      )
                      .orQuery('bool', b =>
                        b
                          .query('range', 'configurable_children.items.reservations.to', { gte: dateFrom })
                          .query('range', 'configurable_children.items.reservations.to', { lte: dateTo })
                      )
                      .orQuery('bool', b =>
                        b
                          .query('range', 'configurable_children.items.reservations.from', { lte: dateFrom })
                          .query('range', 'configurable_children.items.reservations.to', { gte: dateTo })
                      );
                  });
                })
            );
          });
      } else {
        query = query
          .filterMinimumShouldMatch(1)
          .orFilter('bool', attrFilterBuilder)
          .orFilter('bool', b => attrFilterBuilder(b, optionsPrefix).filter('match', 'type_id', 'configurable'));
      }
      // hack because of the ES structure.
      if (hasPrice) {
        let priceObj:any = {};
        appliedFilters.forEach(filter => {
          if (filter.attribute === 'price') {
            priceObj = filter;
          }
        });
        query = query.query('nested', 'path', 'configurable_children', q => {
          return q.query('range', 'configurable_children.price', priceObj.value);
        });
      }
      if (hasSearchString) {
        if (hasAvailability) {
          query = query.query('bool', b =>
            b.orQuery('simple_query_string', { query: searchString + '*', fields: ['name', 'description'] })
          );
        } else {
          query = query.addFilter('bool', b =>
            b.filter('simple_query_string', { query: searchString + '*', fields: ['name', 'description'] })
          );
        }
      }
    }
  }
  // Add aggregations for catalog filters
  const allFilters = searchQuery.getAvailableFilters();
  if (allFilters.length > 0) {
    for (const attrToFilter of allFilters) {
      if (attrToFilter.scope === 'catalog') {
        if (attrToFilter.field !== 'price') {
          const aggregationSize = {
            size:
              config.products.filterAggregationSize[attrToFilter.field] ||
              config.products.filterAggregationSize.default
          };
          query = query.aggregation('terms', getMapping(attrToFilter.field), aggregationSize);
          query = query.aggregation('terms', attrToFilter.field + optionsPrefix, aggregationSize);
        } else {
          query = query.aggregation('terms', attrToFilter.field);
          query.aggregation('range', 'price', config.products.priceFilters);
        }
      }
    }
  }
  // Get searchable fields based on user-defined config.
  const getQueryBody = function (b) {
    const searchableAttributes = config.elasticsearch.hasOwnProperty('searchableAttributes')
      ? config.elasticsearch.searchableAttributes
      : { name: { boost: 1 } };
    const searchableFields = [];
    for (const attribute of Object.keys(searchableAttributes)) {
      searchableFields.push(attribute + '^' + getBoosts(attribute));
    }
    return b.orQuery('multi_match', 'fields', searchableFields, getMultiMatchConfig(queryText)).orQuery('bool', b =>
      b
        .orQuery('terms', 'configurable_children.sku', queryText.split('-'))
        .orQuery('match_phrase', 'sku', { query: queryText, boost: 1 })
        .orQuery('match_phrase', 'configurable_children.sku', { query: queryText, boost: 1 })
    );
  };
  if (queryText !== '') {
    const functionScore = getFunctionScores();
    // Build bool or function_scrre accordingly
    if (functionScore) {
      query = query.query('function_score', functionScore, getQueryBody);
    } else {
      query = query.query('bool', getQueryBody);
    }
  }
  // build
  const queryBody: any = query.build();
  if (searchQuery.suggest) {
    queryBody.suggest = searchQuery.suggest;
  }
  return queryBody;
}
